import React from "react";

import Layout from "../components/layout";

const ContactPage = () => (
    <Layout>
        <h1>contact</h1>
    </Layout>
);

export default ContactPage;
